<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Gerenciar Chamadas</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-body row">
            <div class="col-md-3">
              <base-input v-model="search.key" label="Usuário / E-mail / CPF"/>
            </div>
            <div class="col-md-3">
              <base-input label="Status da Chamada">
                <el-select size="large"
                           class="select-default"
                           placeholder="Selecionar"
                           v-model="search.status">
                  <el-option :value="null"
                             class="select-default"
                             label="Todos"/>
                  <el-option value="OPEN"
                             class="select-default"
                             label="Abertos"/>
                  <el-option value="IN_ANALYSIS"
                             class="select-default"
                             label="Em atendimento"/>
                  <el-option value="CLOSED"
                             class="select-default"
                             label="Fechados"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label="Ordenação Data">
                <el-select size="large"
                           class="select-default"
                           placeholder="Selecionar"
                           v-model="search.dateOrder">
                  <el-option value="ASC"
                             class="select-default"
                             label="Crescente"/>
                  <el-option value="DESC"
                             class="select-default"
                             label="Decrescente"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-sm-2">
              <base-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="item in [5,10,25,50]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label=" ">
                <base-button type="sucess" class="btn btn-success" @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i> Buscar
                </base-button>
              </base-input>
            </div>
            <div class="col-sm-12 mt-5">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column :min-width="45" label="Usuário">
                  <template slot-scope="props">
                      <span>
                        <span><b>@{{props.row.username}}</b></span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="65" label="E-mail">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.email}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Nome">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.name}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="45" label="Telefone">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.phone}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="Tipo">
                  <template slot-scope="props">
                      <span>
                        <span>{{ ticketTypeToLabel(props.row.type) }}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="35" label="Status">
                  <template slot-scope="props">
                      <span>
                        <span>{{ ticketStatusToLabel(props.row.status) }}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="50" label="Criação">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.createdDate}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :width="160" fixed="right" class-name="td-actions" label="Ações">
                  <template slot-scope="props">
                    <span v-if="props.row.status === 'OPEN'">
                      <button type="button" class="btn btn-sm btn-success" size="sm" icon title="Iniciar Atendimento"
                              @click="startTicket(props.row)">
                        <i class="far fa-play-circle"></i>
                      </button>
                    </span>
                    <span v-if="props.row.status === 'IN_ANALYSIS'">
                      <button type="button" class="btn btn-sm btn-danger" size="sm" icon title="Finalizar Atendimento"
                              @click="endTicket(props.row)">
                        <i class="far fa-stop-circle"></i>
                      </button>
                      <span v-if="props.row.type === 'LIMITS_FEES_UPDATE_REQUEST'">
                        <button type="button" class="btn btn-sm btn-info" size="sm" icon title="Alterar Limites"
                                @click="editUserLimits(props.row.username)">
                          <i class="fas fa-chart-line"></i>
                        </button>
                        <button type="button" class="btn btn-sm btn-warning" icon title="Alterar Tarifas"
                                @click="editUserFees(props.row.username)">
                          <i class="fas fa-receipt"></i>
                        </button>
                      </span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info  mt-3">
              <p class="category"> Mostrando {{pagination.fromNumber}} até {{pagination.toNumber}}, de
                {{pagination.totalNumber}} registros.</p>
            </div>
            <div class="col-sm-6  mt-3">
              <p-pagination class="float-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadData">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from '../../../components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "../../../ws.service"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        search: {
          key: '',
          status: null,
          dateOrder: 'ASC'
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        this.tableData = [];
        postWs("/ticket/list",
          true, null,
          {
            key: this.search.key,
            status: this.search.status,
            dateOrder: this.search.dateOrder,
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      editUserLimits(user){
        this.openWindow('/bo/user-operation-limit/edit/' + user)
      },
      editUserFees(user){

      },
      startTicket(row){
        this.updateTicketStatus(row,'IN_ANALYSIS');
      },
      endTicket(row){
        this.updateTicketStatus(row,'CLOSED');
      },
      updateTicketStatus(row, status){
        postWs("/ticket/update",
          true, null,
          {
            id: row.id,
            status: status
          }, this.updateRowStatus,
          failWs)
      },
      updateRowStatus(response){
        this.tableData.forEach((value, index) => {
          if(value.id === response.data.id){
            value.status = response.data.status;
          }
        });
      },
      ticketStatusToLabel(status) {
        if (status == null) {
          return ''
        } else if (status == 'OPEN') {
          return 'ABERTO'
        } else if (status == 'IN_ANALYSIS') {
          return 'EM ANÁLISE'
        } else if (status == 'CLOSED') {
          return 'FECHADO'
        }
        return '??'
      },
      ticketTypeToLabel(type) {
        if (type === 'LIMITS_FEES_UPDATE_REQUEST'){
          return 'Limites/Tarifas';
        }

        return '??';
      },
      openWindow(url) {
        try {
          window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
          );
        } catch (ex) {
          this.$swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
        }
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }

  .btn.btn-primary {
    margin-right: 10px;
  }
</style>
